import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PropTypes from "prop-types"

import PaperEntry from "../components/paper-entry"
import EventTag from "../components/event-tag"
import EventFields from "../components/event-fields"
import "./index.scss"

const MoreCard = ({ link }) => (
  <Link className="undecorated-link" to={link}>
    <div className="index__card more">More&hellip;</div>
  </Link>
)

MoreCard.propTypes = {
  link: PropTypes.string.isRequired,
}

const IndexPage = () => {
  const { papers, events } = useStaticQuery(graphql`
    query HomePageQuery {
      papers: allMarkdownRemark(
        sort: {
          fields: [frontmatter___year, frontmatter___uploadMonth, frontmatter___title]
          order: DESC
        }
        filter: {
          fields: { sourceInstanceName: { eq: "publications" } }
          frontmatter: { feature: { eq: true } }
        }
        limit: 5
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              publication
              authors
              thumbnail {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              year
            }
          }
        }
      }
      events: allMarkdownRemark(
        sort: { fields: [frontmatter___startDate], order: DESC }
        filter: { fields: { sourceInstanceName: { eq: "events" } } }
        limit: 5
      ) {
        edges {
          node {
            id
            frontmatter {
              type
              title
              location
              startDate
              endDate
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <h2>About Us</h2>
      <p>
        Intelligent Data Exploration System (IDEAS) Lab (formerly known as ”VisLab”) was founded
        2016 at Shandong University, Qingdao, China by{" "}
        <a href="http://www.yunhaiwang.net">Prof. Yunhai Wang</a>. Its mission is to enhance
        people&apos;s ability to understand and communicate data through the design of automated
        visualization and visual analytics systems.
      </p>
      <p>
        Recently, we relocated to Renmin University of China and are in the process of building a
        new group. We are currently recruiting faculty members. For more information and to join our
        team, please visit{" "}
        <a href="http://www.yunhaiwang.net/recruit-faculty-en.html">
          http://www.yunhaiwang.net/recruit-faculty-en.html
        </a>
        .
      </p>
      <p>
        We study the perceptual and mathematical foundations of visualization in order to improve
        the efficiency of interactive data analysis, while developing interactive systems for data
        visualization and analysis.
      </p>
      <div className="index__columns">
        <div>
          <h2>Recent Publications</h2>
          <div className="index__card-list">
            {papers.edges.map(({ node: { id: paperId, fields: { slug }, frontmatter } }) => (
              <PaperEntry key={paperId} paper={{ ...frontmatter, slug }} showAuthors={false} />
            ))}
            <MoreCard link="/publications" />
          </div>
        </div>
        <div>
          <h2>Recent Events</h2>
          <div className="index__card-list">
            {events.edges.map(({ node: { id: eventId, frontmatter: event } }) => (
              <Link className="undecorated-link" key={eventId} to={`/events#${eventId}`}>
                <div className="index__card">
                  <div className="index__event-title">
                    <EventTag type={event.type}>{event.type}</EventTag>
                    {event.title}
                  </div>
                  <EventFields event={event} />
                </div>
              </Link>
            ))}
            <MoreCard link="/events" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
